(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Shiny"));
	else if(typeof define === 'function' && define.amd)
		define(["Shiny"], factory);
	else if(typeof exports === 'object')
		exports["notifications"] = factory(require("Shiny"));
	else
		root["notifications"] = factory(root["Shiny"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__541__) {
return 